<template>
  <v-container
    id="usermanagement-roles-users"
    fluid
    tag="section"
  >
    <v-breadcrumbs
      :items="breadcrumbs"
      large
    >
      <v-breadcrumbs-item
        slot="item"
        slot-scope="{ item }"
        exact
        :disabled="item.disabled"
        :to="item.to"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <base-v-component
      :heading="$route.params.roleId"
    />
    <base-material-card
      inline
      icon="mdi-account-multiple"
      :title="`${$t('User')}s`"
      class="px-5 py-3"
    >
      <div class="text-right">
        <!--  Ajout d'un utilisateur-->
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              v-bind="attrs"
              v-on="on"
              @click="resetNewUser"
            >
              {{ $t('usersManagement.Add_user') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('usersManagement.Add_user') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-autocomplete
                  v-model="select"
                  :loading="loading"
                  :items="items"
                  :search-input.sync="search"
                  class="mx-4"
                  flat
                  no-filter
                  hide-no-data
                  hide-details
                  :label="$t('usersManagement.Add_user_modal_title')"
                  solo-inverted
                  item-text="displayName"
                  return-object
                  @change="addNewUser"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                {{ $t('Close') }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              <h3>{{ $t('Name').toUpperCase() }}</h3>
            </th>
            <th class="primary--text">
              <h3>{{ $t('FirstName').toUpperCase() }}</h3>
            </th>
            <th class="primary--text text-right">
              <h3>ACTIONS</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in users"
            :key="item.uid"
          >
            <td>{{ item.nom }}</td>
            <td>{{ item.prenom }}</td>
            <td class="text-right">
              <v-btn
                color="error"
                :title="$t('Delete')"
                class="px-1 ml-1"
                fab
                x-small
                @click="remove(item)"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  // Service
  import UsersManagementService from '@/services/UQAM/UsersManagementService.js'
  // Other
  import Swal from 'sweetalert2'

  export default {
    name: 'UsersManagementRolesUsers',
    data () {
      return {
        dialog: false,
        users: [],
        breadcrumbs: [
          {
            text: this.$i18n.t('sidebar.roles'),
            disabled: false,
            to: '/usersmanagement/roles',
          },
          {
            text: this.$i18n.t('User'),
            disabled: true,
            to: 'breadcrumbs_link_2',
          },
        ],
        loading: false,
        items: [],
        search: null,
        select: null,
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    async mounted () {
      this.start()
    },
    methods: {
      async start () {
        try {
          // Start Spinner
          this.$loading.show()
          this.users = await UsersManagementService.viewRoleUsers(this.$route.params.roleId)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async querySelections (event) {
        // On regarde pour le prénom et le nom
        const text = event.split(' ')
        this.loading = true

        // Le client doit entrer le Prénom et le Nom ou le Nom et le Prénom
        if (text.length >= 2) {
          if (text[1] !== '') {
            try {
              this.items = await UsersManagementService.listUser(event)
              this.loading = false
            } catch (error) {
              console.log(error)
              this.loading = false
            }
          } else {
            this.items = []
            this.loading = false
          }
        } else {
          this.items = []
          this.loading = false
        }
      },
      resetNewUser () {
        this.items = []
        this.search = null
        this.select = null
      },
      async addNewUser () {
        // Start Spinner
        this.$loading.show()
        try {
          const data = { user: this.select.uid, role: this.$route.params.roleId }
          await UsersManagementService.addRoleUsers(data)
          this.start()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      remove (user) {
        // SweetAlert
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('usersManagement.Delete_user')} "${user.prenom} ${user.nom}" ${this.$i18n.t('usersManagement.of_the_role')} "${this.$route.params.roleId}" ?`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: this.$i18n.t('Yes_delete_it'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            // Start Loader
            this.$loading.show()
            try {
              await UsersManagementService.deleteRoleUsers(this.$route.params.roleId, user.uid)
              // Delete item in array
              this.users = this.users.filter(item => (user.uid !== item.uid))
              // Stop Spinner
              this.$loading.hide()
              // SweetAlert
              Swal.fire({
                icon: 'success',
                title: `${this.$i18n.t('Deleted')} !`,
                text: this.$i18n.t('usersManagement.The_user_has_been_deleted'),
              })
            } catch (err) {
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
    },
  }
</script>
